import React from 'react';
import DisplayPrice from './displayPrice';
import LazyLoad from 'react-lazyload';

class DisplayPriceRoot extends React.Component {

    render () {
        return(
            <LazyLoad>
                <DisplayPrice service={this.props.service} withOffer={this.props.withOffer} />
            </LazyLoad>
        )
    }
}
export default DisplayPriceRoot;