import React, {useEffect} from 'react';
import {actions} from "react-fields";

const CommentsCount = (props) => {

    useEffect(
        () => {
            let ids = props.ids;
            const paramsStr = actions.paramsToURI({ids: ids});
            fetch(actions.getData(`/api/comments/for_articles?${paramsStr}`))
                .then(actions.status)
                .then(actions.json)
                .then(function(data) {
                    const placeholders = document.querySelectorAll('[data_comments_count]');
                    placeholders.forEach(function(plchldr) {
                        plchldr.innerText = data[plchldr.getAttribute('data_comments_count')] || 0;
                    });
                });
        }, []
    );

    return null;
};

export default CommentsCount;