import React, {useState, useEffect} from "react";
import cookies from '../cookies';
import Modal from "../IntrusiveModals/modal";
import ConfirmationContent from "./confirmedContent";

export default function ShowConfirmedModal() {

    const [isOpen, setIsOpen] = useState(false);
    const [formType, setFormType] = useState('');

    useEffect(
        () => {
            const formType = cookies.getCookieValue('confirmedsubscription');
            if (formType) {
                setIsOpen(true);
                setFormType(formType);
            }
        },[]
    );

    close = () => {
        cookies.deleteCookie('confirmedsubscription');
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Modal close={close} modalName={''}>
            <ConfirmationContent onClick={close} formType={formType} />
        </Modal>
    );

}