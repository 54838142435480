import React, {Component} from "react";
import Slider from "react-slick";

const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 600,
    cssEase:  'ease-out',
    variableWidth: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    className: 'career-hunter-slider',
    responsive: [
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows:  false,
                variableWidth: false,
                dots: false
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows:  false,
                variableWidth: false,
                dots: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                variableWidth: false,
                dots: false
            }
        }
    ]
};

export default class CareerHunterCarousel extends Component {

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS,
        keyPrefix: Math.random().toString(36).substring(7)
    };

    renderItem = (item, index) => {
        return (<div key={`${this.state.keyPrefix}${index}`} className='card-wrapper'>
            <div className="block">
                <div className="icon-wrapper">
                    <div className={`page-icon auto-centered  ${item.icon}`}/>
                </div>
                <h3 className="text">{item.text}</h3>
            </div>
        </div>)
    };

    render() {
        return(
            <Slider {...this.state.settings}>
                {this.props.items.map((item, index) => this.renderItem(item, index))}
            </Slider>
        )
    }

}