import React from "react";
import LazyLoad from 'react-lazyload';
import CommentsCount from './count';

class CommentsCountLazy extends React.Component {

    render() {
        return (
            <LazyLoad>
                <CommentsCount {...this.props} />
            </LazyLoad>
        )
    }

}

export default CommentsCountLazy;