import React, {Component, Fragment} from "react";

class ArticleCard extends Component {

    componentDidMount() {
        if (this.props.lazyLoading) {
            this.enableLazyLoading();
        }
    }

    enableLazyLoading = () => {
        if (window.lazyLoadInstance){
            window.lazyLoadInstance.update();
        }
        else{
            let _this = this;
            window.setTimeout(_this.enableLazyLoading,100);
        }
    };

    renderCAinfo = () => {
        return <Fragment>
            <meta itemProp="name" content="CareerAddict" />
            <meta itemProp="url" content="https://www.careeraddict.com/"/>
            <div itemScope itemType="http://schema.org/ImageObject" itemProp="logo">
                <meta itemProp="url" content="/images/CA-Logo-Colour.png" />
                <meta itemProp="width" content="180" />
                <meta itemProp="height" content="23" />
            </div>
        </Fragment>
    };

    render() {
        const MainTag = this.props.wrapInDiv ? `div` : `article`;
        let article = this.props.article;
        let careerPath = !!this.props.careerPath && article.career_path;
        let imageProps = {alt: article.name};
        imageProps['src'] = this.props.useLargeImage ? article.large_image_path : article.image_path;
        if (this.props.lazyLoading) {
            imageProps['data-src'] = imageProps['src'];
            imageProps['src'] = '/images/pixel_9_5.gif';
        }

        let hrefProps = {href: article.path};
        if (article.target_blank) { hrefProps['target'] = '_blank'; }

        let seoProps = this.props.withSeo ? {itemType: 'http://schema.org/Article', itemScope: true, itemProp: 'hasPart'} : {};
        let seoImgProps = this.props.withSeo ? {itemType: 'http://schema.org/ImageObject', itemScope: true, itemProp: 'image'} : {};
        return (
            <MainTag {...seoProps} className={`article${careerPath ? ' career' : ''}`} style={{color: article.bg_color}} >
                {this.props.withSeo ? <Fragment>
                    <meta itemProp="headline" content={article.name} />
                    <meta itemProp="articleSection" content={article.category_name} />
                    <meta itemProp="datePublished" content={article.publish_date} />
                    {!!article.modified_date ? <meta itemProp="dateModified" content={article.modified_date} /> : null}
                    {!!article.author_name ? <div itemProp="author" itemScope itemType="http://schema.org/Person">
                        <meta itemProp="name" content={article.author_name} />
                        <meta itemProp="url" content={article.author_url || article.path} />
                    </div> : <div itemProp="author" itemScope itemType="http://schema.org/Organization">
                        {this.renderCAinfo()}
                    </div>}
                    <meta itemProp="mainEntityOfPage" content={""} itemScope itemType="http://schema.org/WebPage" itemID={article.path} />
                    <div itemScope itemType="http://schema.org/Organization" itemProp="publisher">
                        {this.renderCAinfo()}
                    </div>
                </Fragment> : null}

                <div className="article-image" {...seoImgProps} >
                    {this.props.withSeo ? <Fragment>
                        <meta itemProp="url" content={article.large_image_path} />
                        <meta itemProp="width" content="700" />
                        <meta itemProp="height" content="400" />
                    </Fragment> : null}
                    <a {...hrefProps}><img {...imageProps} />   {article.video_url ? <Fragment><div className="layer-img"><div className="small-icon play-button"/></div></Fragment> : null }</a>

                </div>

                {this.props.hideCategory ? null :
                    <a href={careerPath ? article.path : article.category_link} className="article-details-title align-middle" style={{backgroundColor:  careerPath ? "#083A50" : article.bg_color}}>
                        <span className="title">{careerPath && !this.props.featured ? article.job_title : article.category_name}</span>
                    </a>
                }

                {this.props.showTitleOnly ? null :
                    <a {...hrefProps} itemProp="url" className={`article-details main${this.props.shortInfo ? ' article-details--short' : ''}`}
                       style={{color: careerPath ? "#083A50" : article.bg_color}}>
                        <p className={`article-details-desc${careerPath ? ' career' : ''}`}>{careerPath && !this.props.featured ? article.job_description : article.name}</p>
                        {this.props.featured || this.props.shortInfo ? '' :
                            <div className="article-details-wrapper">
                                {careerPath && !this.props.featured ?
                                    <span className="article-details-date">{article.industry_name}</span> :
                                    <time dateTime={article.publish_date}
                                          className="article-details-date">
                                        {article.publish_date_formatted || article.publish_date}
                                    </time>}
                                {careerPath && !this.props.featured ? null :
                                    <React.Fragment>
                                        <div className="article-comments">
                                            <span className="small-icon comments"></span>
                                        </div>
                                        <div className="comments-number">{article.comments_count || 0}</div>
                                    </React.Fragment>
                                }
                            </div>
                        }
                    </a>
                }
            </MainTag>
        )
    }

}
export default ArticleCard;