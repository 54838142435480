import React, {Component} from "react";
import Slider from "react-slick";
import {actions} from "react-fields";

const isClient = typeof window !== 'undefined';
const MOBILE_BREAKPOINT = 640;
const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 600,
    cssEase:  'ease-out',
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'research-slider'
};

const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export default class Feed extends Component {

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS,
        articles: [],
        viewportWidth: 0
    };

    componentDidMount() {
        let _this = this;
        const url = this.props.type === 'study' ? '/api/study?per_page=3' : `/api/press?per_page=3`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({articles: data.articles})
            });
        if (isClient) {
            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
        }
    }
    componentWillUnmount() {
        if (isClient) window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ viewportWidth: window.innerWidth });
    };

    getDate = (date) => {
        let articleDate = new Date(date);
        return `${months[articleDate.getMonth()]} ${articleDate.getFullYear()}`
    };

    url = (article) => {
        return article.full_path;
    };

    renderItem = (article) => {
        return (
            <div className="research-box" key={article.id}>
                <div className="research-img">
                    <a href={this.url(article)} target={'_blank'}>
                        <img src={article.featured_image.thumbnail_path} alt={article.featured_image.alt}/>
                    </a>
                </div>
                <div className="research-details">
                    <a href={this.url(article)}>
                        <h3 className="research-title">{this.props.type === 'study' ? article.label : null} {article.name}</h3>
                    </a>
                    <h5 className="research-date">{this.getDate(article.publish_date)}</h5>
                </div>
            </div>
        )
    }

    render() {
        const isMobile = Boolean(this.state.viewportWidth <= MOBILE_BREAKPOINT);

        return(
            isMobile ? (
                <Slider {...this.state.settings}>
                    {this.state.articles.map((article) => this.renderItem(article))}
                </Slider>
            ) : (
                this.state.articles.map((article) => this.renderItem(article))
            )
        )
    }

}
