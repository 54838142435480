import React, {Component} from "react";
import Slider from "react-slick";
import ArticleCard from "../articles/partials/articleCard";
import LazyLoad from 'react-lazyload';
import SliderArrow from "../base/sliderArrow";

const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 600,
    cssEase:  'ease-out',
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'categories-slider',
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows:  false,
                dots: false
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows:  false,
                dots: false
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false
            }
        }
    ]
};

export default class ArticlesCarousel extends Component {

    constructor(props) {
        super(props);
        let settings = this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS;
        if (this.props.settings && this.props.settings.arrowIcon) {
            settings['arrows'] = true;
            settings['nextArrow'] = <SliderArrow direction="slider-arrow slider-arrow--next"/>;
            settings['prevArrow'] = <SliderArrow direction="slider-arrow slider-arrow--prev"/>;
        }
        let articles = this.props.articles;
        if (this.props.collection) {
            articles = [];
            this.props.collection.map((spot) => {
                articles.push(!!spot.article ? spot.article : spot);
            });
        }
        this.state = {
            settings: settings,
            articles: articles
        };
    }

    renderSlider = () => {
        return(
            <Slider {...this.state.settings}>
                {this.state.articles.map((article) => {
                    return (<div className="card-wrapper" key={`article${article.id}`}>
                        <ArticleCard article={article} showTitleOnly={this.props.showTitleOnly} hideCategory={this.props.hideCategory}
                                     featured={this.props.featured} careerPath={this.props.careerPath} />
                    </div>);
                })}
            </Slider>
        )
    };

    render() {
        if (this.props.lazyLoading) {
            return (
                <LazyLoad>
                    {this.renderSlider()}
                </LazyLoad>
            )
        } else {
            return this.renderSlider()
        }
    }

}