import React from "react";
import LazyLoad from 'react-lazyload';
import Feed from './feed';

class FeedLazy extends React.Component {

    render() {
        return (
            <LazyLoad>
                <Feed {...this.props} />
            </LazyLoad>
        )
    }

}
export default FeedLazy;