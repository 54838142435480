import React from "react";

export default function SliderArrow(props) {
    const { className, style, onClick, direction } = props;
    return (
        <div
            className={className}
            style={{ ...style}}
            onClick={onClick}
        >
            <span className={direction} />
        </div>
    );
}