import React from "react";
import SubscriptionActionHOC from "../../components/subscriptions/subscriptionActionHOC";
import {InputTypeField} from "react-fields";

const SubscriptionForm = SubscriptionActionHOC(props => {
    return (
        <div className={`subscribe input-with-button fade-in`}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Enter email" className={"input-wrapper"} />
            <button className="button-orange" onClick={props.submitForm}>Subscribe <span className="icon"/></button>
        </div>
    )
});

export default function homePageSubscribe() {
    return (
        <SubscriptionForm formType={'home'} />
    )
}
